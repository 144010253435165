@font-face {
  font-family: "neuelt";
  src: url("./assets/fonts/neuelt.ttf");
}

html {
  font-size: 16px !important;
}

body {
  background-color: #EAF2F6 !important;
  font-family: "neuelt", serif, sans-serif !important;
}